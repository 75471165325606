import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

const ComidaDelDia = () => {
  const data = useStaticQuery(graphql`
  query {
      allContentfulMenuItem(
        filter: {
            category: {
                title: {eq: "Comida Del Día"}
            }
        }
        ) {
        edges {
            node {
                title
                id
                description {
                    description
                }
                price
                category {
                    title
                    }
                }
            }
        }
    }
  `)

  const items = data.allContentfulMenuItem.edges;
  return (
    <div>
      <p className="sample">Menu con Horario de 1:00 pm - 3:00 pm</p>
      <ul className="menu-items-grid">
        {
          items.map(({ node }) => {
            return (
                <li key={node.id} className="menu-item">
                    <h3>{node.title}</h3>
                    <p>{node.description.description}</p>
                    <span>${node.price}</span>
                </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default ComidaDelDia;