import React, { useState } from 'react'
import Desayunos from './desayunos'
import Ensaladas from "./ensaladas";
import ComidaDelDia from './comida-del-dia'
import BebidasCalientes from './bebidas-calientes'
import BebidasFrias from './bebidas-frias'
import Postres from './postres'

const Menu = () => {
  const [menuCategory, setMenuCategory] = useState('bebidas-calientes');
  let SelectedCategory;
  if (menuCategory === 'bebidas-calientes' ) {
    SelectedCategory = BebidasCalientes;
  } else if (menuCategory === 'bebidas-frias') {
    SelectedCategory = BebidasFrias
  } else if (menuCategory === 'postres') {
    SelectedCategory = Postres
  } else if (menuCategory === 'desayunos') {
    SelectedCategory = Desayunos
  } else if (menuCategory === 'ensaladas') {
    SelectedCategory = Ensaladas
  } else if (menuCategory === 'comida-del-dia') {
    SelectedCategory = ComidaDelDia
  }
  
  return (
    <section className="menu">
      <h2>Nuestro Menu</h2>
      <ul className="menu-headers">
        <button className="menu-header" onClick={() => setMenuCategory('bebidas-calientes')}>Bebidas Calientes</button>
        <button className="menu-header" onClick={() => setMenuCategory('bebidas-frias')}>Bebidas Frías</button>
        <button className="menu-header" onClick={() => setMenuCategory('postres')}>Postres</button>
        <button className="menu-header" onClick={() => setMenuCategory('desayunos')}>Desayunos</button>
        <button className="menu-header" onClick={() => setMenuCategory('ensaladas')}>Ensaladas</button>
        <button className="menu-header" onClick={() => setMenuCategory('comida-del-dia')} >Comida Del Dia</button>
      </ul>
      <SelectedCategory />
    </section>
  )
}

export default Menu;