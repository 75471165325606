import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Menu from '../components/menu/menu'
// import Instagram from '../components/instagram'
import LeafletMap from '../components/leafletMap'
import Footer from '../components/footer'

const IndexPage = ({data}) => {

return (
  <Layout>
    <SEO title="Home" />
    <section className="hero">
      <Img fluid={data.coffeeShop.childImageSharp.fluid} className="hero-image"/>
    </section>
    <section className="container">
      <span className="description">
        "El amor es un rayo de luna..."<br />
        G.A. Bécquer
      </span>
      <div className="gallery-grid">
        <Img fluid={data.latte.childImageSharp.fluid} className="gallery-img1"/>
        <Img fluid={data.coffeeBags.childImageSharp.fluid} className="gallery-img2"/>
        <Img fluid={data.coffeePortrait.childImageSharp.fluid} className="gallery-img3"/>
      </div>
      <div className="about-grid">
        <h2>Nosotros</h2>
        <p>Somos una empresa familiar que desde el año 2016 ofrece servicios de calidad a cada uno de sus clientes. Elaboramos de manera artesanal alimentos y bebidas, que se preparan con ingredientes provenientes de pequeños productores, apoyando la economía solidaria desde distintas regiones del país como: Oaxaca, Veracruz, Morelos y Puebla. Nuestra misión es proporcionar una experiencia única y personalizada en cada visita.</p>
      </div>
    </section>
    <div className="parallax" />
    <section className="container">
      <Menu />
    </section>
    {typeof window !== 'undefined' &&
      <LeafletMap
        position={[19.360666051446227, -99.16026073680528]} // Your Coordinates
        zoom={18} // Zoom Level
        markerText={"Lua Cafe Cdmx, Bruno Traven 107"} // Icon text
      />
    }
    <Footer />
  </Layout>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    coffeeShop: file(relativePath: { eq: "lua_cafe_hero.jpg" }) {
      ...fluidImage
    }
    coffeePortrait: file(relativePath: { eq: "lua_cafe_ensalada.jpg" }) {
      ...fluidImage
    }
    latte: file(relativePath: { eq: "lua_cafe_lattes.jpg" }) {
      ...fluidImage
    }
    coffeeBags: file(relativePath: { eq: "lua_cafe_sandwich.jpg" }) {
      ...fluidImage
    }
  }
`;

export default IndexPage
