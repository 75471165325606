import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const BebidasCalientes = () => {
  const data = useStaticQuery(graphql`
      query {
          allContentfulDrinkItem(
              filter: {
                  category: {
                      title: { eq: "Bebidas Calientes"}
                  }
              }
          ) {
              edges {
                  node {
                      title
                      id
                      description {
                          description
                      }
                      priceShort
                      priceLong
                      category {
                          title
                      }
                  }
              }
          }
      }
      `)

  const items = data.allContentfulDrinkItem.edges;
  return (
    <div>
      <p className="sample">A la Carta</p>
      <ul className="menu-items-grid">
        {
          items.map(({ node }) => {
            return (
                <li key={node.id} className="menu-item">
                    <h3>{node.title}</h3>
                    <p>{node.description.description}</p>
                    <span>chico ${node.priceShort}</span>
                    <br/>
                    <span>grande ${node.priceLong}</span>
                </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default BebidasCalientes;