import React from 'react'

const Footer = () => {

  return (
    <footer>
      <section>
        <div className="address">
          <h3>¿Donde Estamos?</h3>
          <span>Bruno Traven 107</span>
          <span>03340, Ciudad de México</span>
        </div>
        <div className="hours">
          <h3>Horario</h3>
          <span>Lunes a Viernes de 7 - 6</span>
          <span>Sábado de 8 - 3 </span>
        </div>
        <div className="contact">
          <h3>Servicio a Domicilio</h3>
          <span>55-56-88-18-69</span>
          <span></span>
          <a href="https://www.facebook.com/luacafemx" target="_blank" rel="noreferrer">www.facebook.com/luacafemx</a>
        </div>
      </section>

    </footer>
  )
}

export default Footer;