import React from "react";
import {useStaticQuery, graphql} from "gatsby";

const Ensaladas = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulMenuItem(
                filter: {
                    category: {
                        title: { eq: "Ensaladas"}
                    }
                }
            ) {
                edges {
                    node {
                        title
                        id
                        description {
                            description
                        }
                        price
                        category {
                            title
                        }
                    }
                }
            }
        }
    `)

    const items = data.allContentfulMenuItem.edges;
    return (
        <div>
            <p className="sample">A la Carta</p>
            <ul className="menu-item-grid">
                {
                    items.map(({ node }) => {
                        return (
                            <li key={node.id} className="menu-item">
                             <h3>{node.title}</h3>
                              <p>{node.description.description}</p>
                              <span>${node.price}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Ensaladas;